/* Customize your main colors in :root variables */
:root {
  --main-background-color: #1a2138;
  --card-background-color: #405383;
  --card-background-lighter-color: #1f77cf;
  --main-text-color: #f7f6f4;
  --title-text-color: #dc7ff5;
}

body {
  background-color: var(--main-background-color);
  /*background: url("img/background.png");
  background-size: cover;*/

  font-family: "Anton", cursive;
  font-size: 1.1em;
}

h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}
a {color: white}
